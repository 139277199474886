
.game-desc-title {
	margin-top: 0.5rem;
	margin-left: 2rem;
}

.game-desc-content {
	font-size: 1.1em;
	margin-top: 0.5em;
	margin-left: 2em;
}
